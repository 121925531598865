<template>
  <div>
    <el-drawer :visible.sync="payDrawer" :with-header="true" @closed="close" :show-close="false" :size="size"
      @open="openStaff()">
      <div slot="title" class="title_stafDrawer">
        <div class="stafDrawer_top">
          <span>支付数据统计</span><span> ( 支付数据以支付成功的为基础进行统计，支付失败或者未支付的不算在内 )</span>
        </div>
        <span @click="close" class="back">返回</span>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据总量：</span>
        </div>
        <div class="demo_isone">
          <div class="isone_div"><span style="font-weight:700;font-size:14px">{{ stafDrawerList.pay_all }}</span>
            <br />
            <span style="font-size:14px">累计支付总额</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ stafDrawerList.borrow }}</span>
            <br />
            <span style="font-size:14px">待还借款</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ stafDrawerList.carryOver }}</span>
            <br />
            <span style="font-size:14px">累计结转</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ stafDrawerList.project_num }}</span>
            <br />
            <span style="font-size:14px">已建项目个数</span>
          </div>
        </div>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>今日新增：</span>
        </div>
        <div class="demo_isone">
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_pay_all
          }}</span> <br />
            <span style="font-size:14px">支付</span>
          </div>
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_borrow
          }}</span> <br />
            <span style="font-size:14px">借款</span>
          </div>
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_payBack
          }}</span> <br />
            <span style="font-size:14px">还款</span>
          </div>
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_carryOver
          }}</span> <br />
            <span style="font-size:14px">结转</span>
          </div>
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_project_num
          }}</span> <br />
            <span style="font-size:14px">项目个数</span>
          </div>
        </div>
      </div>

      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据走势：</span>
        </div>
        <div style="text-align:left;font-size:14px">
          <span>数据来源</span>
          <el-select v-model="value" filterable placeholder="请选择" @change="projectChange" size="mini"
            style="margin-left:10px">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code">
            </el-option>
          </el-select>
        </div>

      </div>
      <div class="stafDrawer_demo">
        <div
          style="text-align:left;font-size:14px;display:flex;align-items:center;margin-top:10px;justify-content:space-between">
          <div>
            <span>数据时间</span>
            <el-radio-group v-model="timeType" style="margin-left:10px" @change="typeChange">
              <el-radio-button label="本年"></el-radio-button>
              <el-radio-button label="月份"></el-radio-button>
              <el-radio-button label="近7日"></el-radio-button>
            </el-radio-group>
          </div>

          <el-input type="text" v-if="timeType === '近7日'" placeholder="近7日" disabled size="mini" style="width:100px" />
          <el-select v-if="timeType === '月份'" v-model="date_new" placeholder="请选择" size="mini" @change="changDate">
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input type="text" v-if="timeType === '本年'" placeholder="本年" size="mini" disabled style="width:100px" />
        </div>
      </div>
      <div ref="echartBox1" style="height: 500px"></div>
      <div style="text-align:left;color:#aaaaaa;padding: 0 20px;font-size: 16px">
        <span v-if="timeType === '本年'">{{ date_year }}</span>
        <span v-if="timeType === '月份'">{{ date_month }}</span>
        <span v-if="timeType === '近7日'">{{ timeType }}</span>累计：支付￥ {{ pay_allTotal }} ，借款￥ {{ borrowTotal }}，还款￥ {{
            payBackTotal
        }}，结转￥ {{ carryOverTotal }}
      </div>
    </el-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import * as _ from 'lodash';
import { api } from '/src/api/base';
export const dashboardEnterprisePayUser = api()('/dashboard.enterprise.index.pay.json');
import * as echarts from 'echarts';
export const dashboardEnterpriseIndexPayDatas = api()('/dashboard.enterprise.index.pay.datas.json')
export const projectListAll = api()('project.listAll.json');
export default {
  data () {
    return {
      drawer: this.payDrawer,
      size: '50%',
      timeType: '近7日',
      tempData: null,
      stafDrawerList: {},
      dateList: [
        {
          value: '01',
          label: '1月'
        },
        {
          value: '02',
          label: '2月'
        },
        {
          value: '03',
          label: '3月'
        },
        {
          value: '04',
          label: '4月'
        },
        {
          value: '05',
          label: '5月'
        },
        {
          value: '06',
          label: '6月'
        },
        {
          value: '07',
          label: '7月'
        },
        {
          value: '08',
          label: '8月'
        },
        {
          value: '09',
          label: '9月'
        },
        {
          value: '10',
          label: '10月'
        },
        {
          value: '11',
          label: '11月'
        },
        {
          value: '12',
          label: '12月'
        }
      ],
      date_new: '',
      borrowTotal: '',
      carryOverTotal: '',
      payBackTotal: '',
      pay_allTotal: '',
      date_year: '',
      date_month: '',
      options: [],
      value: ''
    }
  },
  components: {

  },
  props: {
    payDrawer: Boolean
  },
  mounted () {

  },
  methods: {
    async projectChange (e) {
      console.log(e, this.value);
      await this.getData()
    },
    async getProjectList () {
      const data = await projectListAll({

      })
      console.log(data);
      this.options = data
    },
    async openStaff () {
      this.loadData()
      this.date_new = moment().format('MM')
      this.date_month = moment().format('YYYY年MMM')

      this.date_year = moment().format('YYYY年')
      await this.getData();
      this.getProjectList()
      setTimeout(() => {
        this.setOption();

      }, 1000);
    },
    async loadData () {
      const data = await dashboardEnterprisePayUser({

      })
      console.log(data);
      this.stafDrawerList = data
    },
    close () {
      this.drawer = false;
      this.value = ''
      this.timeType = '近7日'
      console.log(this.timeType);
      this.$emit('close', this.drawer)
    },
    async changDate (e) {
      this.date_month = this.date_month.slice(0, 5) + e.slice(1, 2) + '月'
      this.date_new = e
      await this.getData()

    },
    async getData () {
      let params = {}
      if (this.timeType === '月份') {
        params = {
          type: this.timeType === '近7日' ? 'week' : this.timeType === '月份' ? 'month' : 'year',
          month: moment().format('YYYY') + this.date_new,
          projectCode: this.value
        }
      }
      else {
        params = {
          type: this.timeType === '近7日' ? 'week' : this.timeType === '月份' ? 'month' : 'year',
          projectCode: this.value
        }
      }
      const data = await dashboardEnterpriseIndexPayDatas(params);
      this.tempData = data;


      this.borrowTotal = _.sumBy(data.borrow, it => it.num).toFixed(2)
      this.carryOverTotal = _.sumBy(data.carryOver, it => it.num).toFixed(2)
      this.payBackTotal = _.sumBy(data.payBack, it => it.num).toFixed(2)
      this.pay_allTotal = _.sumBy(data.pay_all, it => it.num).toFixed(2)
      this.setOption()
    },
    setOption () {
      const list = this.tempData;
      var myChart = echarts.init(this.$refs.echartBox1);
       window.onresize = function() {
    myChart.resize();
  };
      var option = {
        grid: {
          top: '18%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
          formatter: (params) => {
            return `<div style="text-align:left;padding:5px">${this.timeType === '本年' ? moment(params[0].name).format('YYYY-MM') : moment(params[0].name).format('YYYY-MM-DD')}<br/>${params.map(n => `${n.seriesName}:${n.value}元`).join('<br/>')}</div>`;
          },
        },
        legend: {
          orient: 'horizontal',
          right: '10%',
          top: '10%',
          data: ['结转', '还款', '借款', '支付'],
          textStyle: {
            'fontSize': 14,
          },
          icon: 'rect',
        },
        xAxis: {
          name: '日期',
          nameTextStyle: {
            color: '#595959',
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: list.borrow.map(it => it.date),
        },

        yAxis: {
          name: '人数',
          nameTextStyle: {
            align: 'center',
            color: '#595959',
          },
        },
        series: [
          {
            name: '结转',
            type: 'line',
            itemStyle: {
              color: '#8400ff',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.carryOver.map(it => it.num),
          },

          {
            name: '还款',
            type: 'line',
            itemStyle: {
              color: '#02a7f0',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.payBack.map(it => it.num),
          },
          {
            name: '借款',
            type: 'line',
            itemStyle: {
              color: '#6db103',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.borrow.map((it) => it.num),
          },
          {
            name: '支付',
            type: 'line',
            itemStyle: {
              color: '#f59a23',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.pay_all.map((it) => it.num),
          },
        ],
      };
      myChart.setOption(option, true);

    },
    async typeChange () {
      await this.getData();
      this.setOption();
    },
  },

}
</script>
<style >
.el-drawer__header {
  text-align: left;
}

.el-radio-button__inner {
  padding: 5px 10px;
  border-radius: 0;
}
</style>
<style lang="scss" scoped>
.title_stafDrawer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .stafDrawer_top {
    span:nth-child(1) {
      font-size: 20px;
      color: #333333;
    }

    span:nth-child(2) {
      color: #b2aabd;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .back {
    color: #1989fa;
    font-size: 14px;
  }
}

.stafDrawer_demo {
  padding: 0 20px;
}

.demo_isone {
  padding: 20px;
  display: flex;
  align-items: center;

  .isone_div {
    padding: 20px 30px;
  }
}
</style>